import { Button, Col, Divider, Form, Image, Input, InputNumber, Modal, Row, Select, Table, Upload, message } from 'antd';
import { useEffect, useState } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { currencyFormat } from '../../Utils/formatCurrency';
import { urlReceipt } from '../../Utils/globalUrl';
import { dateFormat } from '../../Utils/formatDate';
import moment from 'moment';
import { formatNumber } from '../../Utils/formatNumber';
const { Dragger } = Upload;

function InputVerification() {
    const token = useSelector((state) => state.user.token);
    const [form] = Form.useForm();
    const [formModal] = Form.useForm();

    const [isModal, setIsModal] = useState(false);
    const [valueModal, setValueModal] = useState(null);
    const [imageReceiptUrl, setImageReceiptUrl] = useState(null);
    const [imageReceiptUrlModal, setImageReceiptUrlModal] = useState(null);
    const [imageReceiptFile, setImageReceiptFile] = useState(null);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        var url = '/pointverification?page=' + page.pagination.current;
        getApi('GET', url, 'verify');
        if (customer.length === 0) {
            getApi('GET', '/customer?nopage=true', 'customer');
        }
        if (employee.length === 0) {
            getApi('GET', '/employee/menu?menu_id=8&nopage=true', 'employee');
        }
    }, [page.pagination.current, customer.length]);

    const getApi = (method, url, type, data = null, header = null) => {
        setLoading(true);
        var headers;
        if (header !== null) {
            headers = header;
        } else {
            headers = {
                Authorization: 'Bearer ' + token
            }
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: headers
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'verify':
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'customer':
                        var c = [];
                        for (let i = 0; i < res.data.rows.length; i++) {
                            c = [...c, {
                                value: res.data.rows[i].customer_id,
                                label: res.data.rows[i].phone + ' (' + res.data.rows[i].name + ')'
                            }];
                        }
                        setCustomer(c);
                        setLoading(false);
                        break;
                    case 'inputverification':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        })
                        form.setFieldsValue({
                            transaction_code: null,
                            grand_total: null,
                            outlet_id: null,
                            customer_id: null,
                        })
                        getApi('GET', '/pointverification?page=1', 'verify');
                        setImageReceiptFile(null);
                        setImageReceiptUrl(null);
                        setLoading(false);
                        message.success(res.message);
                        closeModal();
                        break;
                    case 'employee':
                        var employee = [];
                        for (let i = 0; i < res.data.length; i++) {
                            employee = [...employee, {
                                value: res.data[i].user_id,
                                label: res.data[i].detail_user.first_name + ' ' + res.data[i].detail_user.last_name
                            }];
                        }
                        setEmployee(employee);
                        setLoading(false);
                        break;
                    case 'checkCashbackMember':
                        // var point = 0;
                        // for (let i = 0; i < res.data.length; i++) {
                        //     const pointProd = res.data[i];
                        //     const item = data2[i];
                        //     if (pointProd.status === 'percent') {
                        //         if (item.edit_price !== undefined) {
                        //             point += Number(item.edit_price) * Number(item.qty) * (Number(pointProd.point) / 100);
                        //         } else {
                        //             point += Number(posPriceNum(item, isEcommerce)) * Number(item.qty) * (Number(pointProd.point) / 100);
                        //         }
                        //     } else {
                        //         point += Number(item.qty) * Number(pointProd.point);
                        //     }
                        // }
                        // data.data_verify.point = point;
                        // inputPointVerify(data.data_verify);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                message.error(res.message)
            }
        }).catch((e) => {
            console.log(e)
            setLoading(false);
        })
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img.originFileObj);
    };

    const onUploadReceipt = (info) => {
        setImageReceiptFile(info.file.originFileObj);
        getBase64(info.file, (url) => {
            if (isModal === true)
                setImageReceiptUrlModal(url);
            else
                setImageReceiptUrl(url);
        });
    }

    const save = (ev) => {
        var statusImage = false;
        if (valueModal !== null) {
            if (imageReceiptUrlModal !== null && imageReceiptUrlModal !== undefined) {
                statusImage = true;
            } else {
                statusImage = false;
            }
        } else {
            if (imageReceiptUrl !== null && imageReceiptUrl !== undefined) {
                statusImage = true;
            } else {
                statusImage = false;
            }
        }

        if (statusImage === true) {
            if (imageReceiptFile !== null && imageReceiptFile !== undefined) {
                ev.image = imageReceiptFile;
            }
            if (valueModal !== null && valueModal !== undefined) {
                ev.transaction_id = valueModal.transaction_id;
                ev.point_id = valueModal.point.point_id
            }
            //convert json to form
            const form = new FormData();
            for (const name in ev) {
                form.append(name, ev[name]);
            }
            var header = {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data',
            }

            if (valueModal !== null && valueModal !== undefined) {
                getApi('PUT', '/inputverification/' + valueModal.verify_point_id, 'inputverification', form, header);
            } else {
                getApi('POST', '/inputverification', 'inputverification', form, header);
            }
        } else {
            message.error('Upload Struk terlebih dahulu!');
        }
    }

    const titlePage = 'Input Verifikasi';

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'No. Struk',
            dataIndex: 'transaction_code',
            key: "transaction_code",
            render: (id, record, index) => { return record.transaction.transaction_code; },
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",
            render: (id, record, index) => { return dateFormat(record.created_at) },
        },
        {
            title: 'Nomor Customer',
            dataIndex: 'customer',
            key: "customer",
            render: (id, record, index) => { return record.customer.name },
        },
        {
            title: 'Total Transaksi',
            dataIndex: 'grand_total',
            key: "grand_total",
            render: (id, record, index) => { return currencyFormat(record.transaction.grand_total); },
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: "point",
            render: (id, record, index) => {
                return formatNumber(record.point.point) + ' point';
            },
        },
        {
            title: 'Lampiran',
            dataIndex: 'receipt',
            key: "receipt",
            render: (id, record, index) => {
                return (
                    <>
                        <Image
                            preview={record.file_receipt !== null ? true : false}
                            src={record.file_receipt !== null ? record.file_receipt : '/assets/photo_empty.png'}
                            height={50}
                            width={50}
                        />
                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.point.status === 'Approved') {
                    return (
                        <>
                            <p style={{ marginLeft: 5, color: "green" }}>Telah Disetujui</p>
                        </>
                    );
                } else if (record.point.status === 'Rejected') {
                    return (
                        <>
                            <p style={{ marginLeft: 5, color: "red" }}>Telah Ditolak</p>
                        </>
                    );
                } else {
                    return (
                        <>
                            <p style={{ marginLeft: 5, color: "orange" }}>Sedang Diproses</p>
                        </>
                    );
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                if (record.point.status === 'Rejected')
                    return (
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal(record)}>
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                    );
                else
                    return null;
            }
        },
    ];

    const openModal = (record) => {
        formModal.setFieldsValue({
            transaction_code: record.transaction.transaction_code,
            grand_total: record.transaction.grand_total,
            outlet_id: record.transaction.outlet.outlet_id,
            customer_id: record.customer.customer_id,
        })
        setImageReceiptUrlModal(record.file_receipt)
        setValueModal(record);
        setIsModal(true);
    }

    const closeModal = () => {
        formModal.setFieldsValue({
            transaction_code: null,
            grand_total: null,
            outlet_id: null,
            customer_id: null,
        })
        setValueModal(null);
        setImageReceiptUrlModal(null);
        setIsModal(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <div>
            <div>
                <Helmet>
                    <title>{titlePage}</title>
                </Helmet>
                <h3>Input Verifikasi</h3>
                <Form form={form} name="form_outlet" layout="vertical" onFinish={save}>
                    <Row>
                        <Col span={14}>
                            <Form.Item
                                name="transaction_code"
                                label="No. Struk"
                                rules={[
                                    {
                                        required: true,
                                        message: 'No. Struk kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='No. Struk' />
                            </Form.Item>
                            <Form.Item
                                name="customer_id"
                                label="No. HP"
                                rules={[
                                    {
                                        required: true,
                                        message: 'No. HP kosong!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    placeholder="Pilih Customer"
                                    options={customer}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10} style={{ display: 'flex', justifyContent: 'center' }} >
                            <div>
                                <p style={{ textAlign: "center" }}>Upload Struk</p>
                                <div style={{ height: 230, width: 300, borderWidth: 1, border: '1px solid #F2F2F2', }}>
                                    {
                                        imageReceiptUrl !== null && imageReceiptUrl !== undefined ?
                                            <div style={{
                                                width: 300,
                                                height: 230,
                                                overflow: 'hidden',
                                                // display: 'flex'
                                            }}>
                                                <Image
                                                    src={imageReceiptUrl}
                                                    style={{
                                                        width: 300,
                                                        height: 230,
                                                        objectFit: "contain",
                                                    }} />
                                            </div>
                                            :
                                            <Dragger onChange={(ev) => onUploadReceipt(ev)}>
                                                <p className="ant-upload-drag-icon">
                                                    <FileTextOutlined />
                                                </p>
                                                <p className="ant-upload-text">Upload Struk disini</p>
                                                <p className="ant-upload-hint">
                                                    Upload Struk untuk verifikasi transaksi dan point
                                                </p>
                                            </Dragger>
                                    }
                                </div>
                                {
                                    imageReceiptUrl !== null && imageReceiptUrl !== undefined &&
                                    <div style={{ marginTop: 10, textAlign: "right" }}>
                                        <Button type='default' danger style={{ marginRight: 5 }} onClick={() => setImageReceiptUrl(null)}>
                                            <div style={{ fontSize: 12 }}>Hapus Gambar</div>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Button type='primary' style={{ marginRight: 5 }} onClick={() => form.submit()}>
                        <div style={{ fontSize: 12 }}>Kirim Verifikasi</div>
                    </Button>
                </Form>
                <Divider />
                <h3>Daftar Verifikasi</h3>
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    pagination={page.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
                <Modal title="Form Verificaton" open={isModal} onOk={formModal.submit} onCancel={() => closeModal()} okText="Simpan" >
                    <Form form={formModal} name="form_input_verification" layout="vertical" onFinish={save}>
                        <Form.Item
                            name="transaction_code"
                            label="No. Struk"
                            rules={[
                                {
                                    required: true,
                                    message: 'No. Struk kosong!'
                                },
                            ]}
                        >
                            <Input placeholder='No. Struk' />
                        </Form.Item>
                        <Form.Item
                            name="grand_total"
                            label="Grand Total"
                            rules={[
                                {
                                    required: true,
                                    message: 'Grand Total kosong!'
                                },
                            ]}
                        >
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                style={{ width: '100%' }}
                                // value={pay}
                                placeholder="Grand Total"
                            // onChange={(val) => setPay(val)}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            name="outlet_id"
                            label="Nama Outlet"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Outlet kosong!'
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder="Pilih Outlet"
                                options={outlet}
                            />
                        </Form.Item> */}
                        <Form.Item
                            name="customer_id"
                            label="No. HP"
                            rules={[
                                {
                                    required: true,
                                    message: 'No. HP kosong!'
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder="Pilih Outlet"
                                options={customer}
                            />
                        </Form.Item>
                        <Form.Item
                            name="user_id"
                            label="Pilih Admin Konfirmasi"
                            rules={[
                                {
                                    required: true,
                                    message: "Pilih Admin Konfirmasi"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder="Pilih Konfirmasi Admin"
                                options={employee}
                            />
                        </Form.Item>
                    </Form>
                    <p><b style={{ color: 'red' }}>*</b> Upload Struk</p>
                    {
                        imageReceiptUrlModal !== null && imageReceiptUrlModal !== undefined ?
                            <div style={{
                                width: 300,
                                height: 230,
                                overflow: 'hidden',
                                // display: 'flex'
                            }}>
                                <Image
                                    src={imageReceiptUrlModal}
                                    style={{
                                        objectFit: "contain",
                                    }} />
                            </div>
                            :
                            <Dragger onChange={(ev) => onUploadReceipt(ev)}>
                                <p className="ant-upload-drag-icon">
                                    <FileTextOutlined />
                                </p>
                                <p className="ant-upload-text">Upload Struk disini</p>
                                <p className="ant-upload-hint">
                                    Upload Struk untuk verifikasi transaksi dan point
                                </p>
                            </Dragger>
                    }
                    {
                        imageReceiptUrlModal !== null && imageReceiptUrlModal !== undefined &&
                        <div style={{ marginTop: 10, textAlign: "left" }}>
                            <Button type='default' danger style={{ marginRight: 5 }} onClick={() => setImageReceiptUrlModal(null)}>
                                <div style={{ fontSize: 12 }}>Hapus Gambar</div>
                            </Button>
                        </div>
                    }
                </Modal>
            </div>
        </div>
    );
}

export default InputVerification;